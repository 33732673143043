<template lang="pug">
v-container(fill-height,fluid,grid-list-xl)
  v-flex(md12)
    DataTable(
      :fields="fields"
      :dados="dados"
      :loaded="loaded"
      :order="order"
    )
</template>

<script>
import { mapMutations } from 'vuex';
import { erros as api } from '@/api';

export default {
  data: () => ({
    loaded: false,
    dados: [],
    fields: api.fieldsAgrupados,
    order: api.orderAgrupados,
  }),
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
  },
  async mounted() {
    this.isControl(true);
    this.setTitle('Erros');

    api.refreshAuthProd(this.$store.state.auth);
    try {
      this.dados = await api.getAgrupados();
      this.loaded = !!this.dados;
    } catch (e) {
      this.$store.dispatch('alert/error',
        'Ocorreu algum problema ao buscar as informações',
        { root: true });
      this.loaded = false;
    }
  },
  created() {
    this.$store.dispatch('auth/verify');
  },
};
</script>
